import { store } from "@/internal";
import {
  ActionSubheader,
  TableFieldType,
  TableHeader
} from "helix-vue-components";

export const VehicleGeneralActions: ActionSubheader[] = [
  {
    action: () => {
      store.dispatch("RouterModule/go", {
        name: "vehicle"
      });
    },
    icon: "fal fa-plus"
  }
];

export const VehicleHeaders: TableHeader[] = [
  {
    label: "name",
    value: "name",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    label: "vehicles.make",
    value: "make",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    label: "vehicles.model",
    value: "model",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    label: "vehicles.color",
    value: "color",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    label: "vehicles.year",
    value: "year",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    label: "vehicles.license_plate_number",
    value: "license_plate",
    fieldComponent: TableFieldType.string,
    sortable: true
  }
];
