import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { pusherEvents } from "@/enums/pusherEvents";
import { Vehicle } from "@/interfaces/vehicle";
import { VehicleHeaders } from "@/metadata/vehicle";
import { vehiclesService } from "@/services/vehicles.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  BooleanCheck,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./VehicleList.template.vue";

const namespace = "VehicleModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(vehiclesService)
export default class VehicleListComponent extends Vue {
  public tableHeaders = VehicleHeaders;
  public generalActions = vehiclesService.getGeneralActions();
  public rowActions = vehiclesService.getRowActions();
  public multiActions = vehiclesService.getMultiActions();
  public paginate = vehiclesService.paginationAction();

  @Getter("vehicles", { namespace })
  public vehicles!: Vehicle[];

  @Getter("pagination", { namespace })
  public pagination!: TablePagination | null;

  @Getter("loading", { namespace })
  public loading!: boolean;

  @Action("loadVehicles", { namespace })
  public loadVehicles!: Callback;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public filter(header: TableHeader) {
    vehiclesService.sortQuery(header);
  }

  get canSelectMultiple() {
    return this.hasPermission(policyList.deleteVehicles);
  }

  protected mounted() {
    this.$changes.watch(pusherEvents.vehicle, this.loadVehicles);
    this.setPageNav({
      title: "vehicles.title",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.loadVehicles();
    this.tableHeaders.map(h => {
      h.label = this.$t(h.label).toString();
      return h;
    });
  }
}
